import React from 'react'
import Layout from '../layout/Layout'
import TemplateOneSection from '../ui/TemplateOneSection'
import ThemeHeading from '../ui/ThemeHeading'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {connect} from 'react-redux'
import {
    fetchPublicGalleryImages
} from '../../actions'
import GalleryCarousel from '../ui/GalleryCarousel'
import {Link} from 'react-router-dom'

import img1 from '../../assets/img/02@2x.jpg'

//blogs
// import blog1 from '../../assets/img/studio-samples/Blogs/African-Moods@2x.jpg'
// import blog2 from '../../assets/img/studio-samples/Blogs/African-Samples@2x.jpg'
// import blog3 from '../../assets/img/studio-samples/Blogs/Indian Moods@2x.jpg'
import blog4 from '../../assets/img/studio-samples/Blogs/azaahn-and-ginger-sparrow-mood-c-and-h.jpg'
import blog5 from '../../assets/img/studio-samples/Blogs/Curly-Mohair-and-Karakul-wool-blend-with-thin-spun-border-mood.jpg'

//pdfs
// import pdf1 from '../../assets/pdfs/AFRICAN-MOODS-emailer.01.04.2022.pdf'
// import pdf2 from '../../assets/pdfs/African-Samples-CoralandHive-2022.pdf'
// import pdf3 from '../../assets/pdfs/INDIAN-MOODS-emailer-01.04.2022.pdf'
import pdf4 from '../../assets/pdfs/Coral-and-Hive-Mood-Cards-June-2023.pdf'
import pdf5 from '../../assets/pdfs/Coral-and-Hive-Studio-samples-2023.pdf'

class StudioSamples extends React.Component{

    componentDidMount(){
        this.props.fetchPublicGalleryImages('studio-samples')
    }

    renderGallery = () => {

        // let gallery = this.props.data

        // // sort by alt
        // gallery.sort(function(a, b) {
        //     var nameA = a.caption.toUpperCase(); // ignore upper and lowercase
        //     var nameB = b.caption.toUpperCase(); // ignore upper and lowercase
        //     if (nameA < nameB) {
        //         return -1;
        //     }
        //     if (nameA > nameB) {
        //         return 1;
        //     }
        
        //     // names must be equal
        //     return 0;
        // });

        // return <MasonaryGridBlog data={this.props.data} />
        return <GalleryCarousel data={this.props.data} />
    }

    render(){

        return(
            <>
            <ScrollToTop />
            <Seo 
                title="Studio Samples | Coral & Hive - Natural Handwoven Rugs"
                description="You’re welcome to make an appointment with our creative studios to come and view samples. If you’d like a sample selection for your design studio or home, you can order these through our creative studios."
                image={window.location.protocol + '//' + window.location.hostname + img1}
            />
                <Layout transparent={false}>
                    <TemplateOneSection>
                        <ScrolledIntoView>
                            <ThemeHeading>
                                <h1>STUDIO SAMPLES</h1>
                            </ThemeHeading>
                        </ScrolledIntoView>
                        <ScrolledIntoView>
                            <p>You are welcome to visit our creative studios (London and Cape Town) to meet with our design consultants and see natural rug samples. Please contact us to make an appointment - we'd love to meet you!</p>
                            <Link to='/contact' className='_button'>Get In Touch</Link>
                        </ScrolledIntoView>
                    </TemplateOneSection>
                    <section className="_section _gallery-carousel-section">
                        <ScrolledIntoView>
                            {this.props.data && this.renderGallery()}
                        </ScrolledIntoView>
                    </section>
                    <section className='_section _section-catalogue'>
                        <div className='_grid'>
                            <div className='_column _center'>
                                <div className='_hr' />
                                <h2>ORDER SAMPLES FOR YOUR OWN STUDIO PROJECTS.</h2>
                                <p style={{marginBottom: '60px'}}>We offer samples at cost price plus shipping, and will reimburse the cost of the sample when a rug is ordered. Download our rug and sample catalogues for a closer look at our latest offerings.</p>
                            </div>
                        </div>
                        <div className='_grid _grid-3'>
                            <div className='_column'>
                                <img src={blog4} alt="African Moods" />
                                <h2>C+H Moods 2023</h2>
                                <a href={pdf4} className='_button' target="_blank" rel="noreferrer">Download</a>
                                {/*<a href={pdf4}  download="Coral-and-Hive-Mood-Cards-June-2023.pdf" className='_button' target="_blank" rel="noreferrer">Download</a>*/}
                            </div>
                            <div className='_column'>
                                <img src={blog5} alt="African Samples" />
                                <h2>C+H Studio Samples 2023</h2>
                                <a href={pdf5} className='_button' target="_blank" rel="noreferrer">Download</a>
                                 {/*<a href={pdf5} download="Coral-and-Hive-Studio-samples-2023.pdf" className='_button' target="_blank" rel="noreferrer">Download</a>*/}
                            </div>
                            {/*<div className='_column'>
                                <img src={blog1} alt="African Moods" />
                                <h2>African Moods</h2>
                                <a href={pdf1}  download="Africa-Moods-Coral-and-Hive-2022.pdf" className='_button' target="_blank" rel="noreferrer">Download</a>
                            </div>
                            <div className='_column'>
                                <img src={blog2} alt="African Samples" />
                                <h2>African Samples</h2>
                                <a href={pdf2} download="African-Samples-Coral-and-Hive-2022.pdf" className='_button' target="_blank" rel="noreferrer">Download</a>
                            </div>
                            <div className='_column'>
                                <img src={blog3} alt="Indian Moods" />
                                <h2>Indian Moods</h2>
                                <a href={pdf3} download="India-Moods-Coral-and-Hive-2022.pdf" className='_button' target="_blank" rel="noreferrer">Download</a>
                            </div>*/}
                        </div>
                    </section>
                </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.publicGalleryImagesState['studio-samples']
    }
}

export default connect(mapStateToProps, {
    fetchPublicGalleryImages
})(StudioSamples)