import React from 'react'
import Layout from '../layout/Layout'
import TemplateOneSection from '../ui/TemplateOneSection'
import ThemeHeading from '../ui/ThemeHeading'
import TemplateImageSection from '../ui/TemplateImageSection'
import img from '../../assets/img/colours-feature-1@2x@2x.jpg'
import imghover from '../../assets/img/colours-feature-2@2x@2x.jpg'
import GetInTouch from '../ui/GetInTouch'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
// import MasonaryGridBlog from '../ui/MasonaryGridBlog'
import ScrollToTop from '../../ScrollToTop'
import {connect} from 'react-redux'
import {
    fetchPublicGalleryImages
} from '../../actions'
import {BASE_PATH} from '../../paths'

class Colours extends React.Component{

    componentDidMount(){
        this.props.fetchPublicGalleryImages('patterns')
    }

    renderGalleryV2 = () => {
        let gallery = this.props.data

        return gallery.map(({image, image2, alt, caption, id}) => {
            return (
                <div className='_column'>
                    <figure key={id} style={{
                        backgroundImage: image2 ? `url(${BASE_PATH + image2})` : 'none'
                    }}
                        className={`${image2 ? '_animation-image-swith' : ''}`}
                    >
                        <img src={BASE_PATH + image} alt={alt ? alt : ""} />
                        <figcaption className='_figcapion'>{caption ? caption : ''}</figcaption>
                    </figure>
                </div>
            )
        })
    }

    render(){
        
        return(
            <>
                <ScrollToTop />
                <Seo 
                    title="Colours + Patterns | Coral & Hive - Natural Handwoven Rugs"
                    description="While we do work with fibres that are exquisite in their natural tones, our rug fibres can be dyed according to your colour selection if you want to add colour into your creation. Come and play with our pom pom box full of our favourite 36 close to nature colours and have your own design woven up in colours to match your mood."
                    image={window.location.protocol + '//' + window.location.hostname + img}
                />
            
                <Layout transparent={false}>
                    <TemplateOneSection>
                        <ScrolledIntoView>
                            <ThemeHeading>
                                <h1>COLOURS</h1>
                            </ThemeHeading>
                        </ScrolledIntoView>
                        <ScrolledIntoView>
                            <p>While we work with fibres that are exquisite in their natural tones, we also love to work with colour. All of our fibres can be dyed, choose your colours from our Close to Nature colour box.</p>
                        </ScrolledIntoView>
                    </TemplateOneSection>
                    <TemplateImageSection img={img} img2={imghover} alt="Colours" className="_padding-bottom" />
                    <GetInTouch className="_padding-bottom-none" />
                    <TemplateOneSection className="_padding-bottom-none">
                        <ScrolledIntoView>
                        <ThemeHeading>
                            <h2>PATTERNS</h2>
                        </ThemeHeading>
                        </ScrolledIntoView>
                        <ScrolledIntoView>
                            <p>Our Coral & Hive patterns are inspired by the things that make us slow down and breathe ~ the beauty of our natural surroundings, the soulful songs of our weavers, inspiring art, calming organic shapes and forms. Lose yourself in our pattern gallery and choose something you love.</p>
                        </ScrolledIntoView>
                    </TemplateOneSection>
                    {/* <section className="_section _section-template-1 _center">
                        <div className="_box">
                            <div className="_inner">
                                {this.props.data && this.renderGallery()}
                            </div>                        
                        </div>
                    </section> */}
                    <section className="_section _section-template-1">
                        <div className="_grid _grid-4">
                            {this.props.data && this.renderGalleryV2()}                       
                        </div>
                    </section>
                    <GetInTouch />
                </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.publicGalleryImagesState['patterns']
    }
}

export default connect(mapStateToProps, {
    fetchPublicGalleryImages
})(Colours)