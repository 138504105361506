import React from 'react'
import HeaderContent from './HeaderContent'
import NavLinks from './NavLinks'
import Copyright from '../ui/Copyright'
import CloseIcon from '../ui/CloseIcon';
import CartProfileIcons from '../ui/CartProfileIcons';
import SocialIcons from '../ui/SocialIcons';
import Logo from '../ui/Logo';

class Nav extends React.Component {

    constructor(props){
        super(props)
        this.ref = React.createRef()        
    }

    componentDidMount(){
        document.addEventListener("click", e => this.removeClassNames(e))
    }

    componentWillUnmount(){
        document.removeEventListener("click", e => this.removeClassNames(e))
    }

    removeClassNames = e => {
        if(this.ref){
            if(this.ref.current){
                if(!this.ref.current.contains(e.target)){
                    const navLinks = document.querySelectorAll("._nav-link a")
                    for(let i = 0; i < navLinks.length; i++){
                        if(navLinks[i].nextElementSibling){
                            navLinks[i].nextElementSibling.classList.remove('_show-list')
                            navLinks[i].classList.remove('_up');   
                        }                    
                    }
                }
            }            
        }        
    }

    renderIcon(){
        return <CloseIcon onClick={this.props.onClick} fill={'#FFFFFF'} />;
    }

    renderLogo = () => {
            return <Logo fill={'#000000'} />;
    };


    render(){
        return(
            <nav className="_nav">
                <div className="_nav-header" >
                <div className="_inner">
                <div className="_item">
                    <SocialIcons fill={'#000000'} />
                </div>
                <div className="_item">{this.renderLogo()}</div>
                <div className="_item">
                    <div className="_left-actions-flexbox">
                        <CartProfileIcons fill={'#000000'} />
                        {this.renderIcon()}
                    </div>
                </div>
            </div>
                </div>
                <NavLinks onClick={this.props.onClick} />
                <Copyright />
            </nav>
        )
    }
}

export default Nav