import {Link} from 'react-router-dom'
import icon from '../../assets/img/profile.svg'
import icon2 from '../../assets/img/profile copy.svg'

const ProfileIcon = (props) => {
  return (
    <Link to="/my-account" className='_profile-icon'>
      {props.fill === "#FFFFFF" ? <img src={icon} alt="Profile" /> : <img src={icon2} alt="Profile" />}
    </Link>
  )
}

export default ProfileIcon