import React from 'react'

const Copyright = () => {

    const date = new Date()
    const year = date.getFullYear()

    return(
        <p className="_copyright" style={{color: 'black'}}>All original content © {year} Coral & Hive. Powered by <a href="https://optimalonline.co.za" target="_blank" rel="noreferrer">Optimal Online</a></p>
    )
}

export default Copyright