import React from 'react'

const CloseIcon = ({onClick, fill}) => {
    const spanStyle = {
        backgroundColor: '#000000', // Use the `fill` prop to set the background color

    };
    return(
        <button className="_close-icon" onClick={onClick}>
            <div>
                <span style={spanStyle}/>
                <span style={spanStyle}/>
            </div>
        </button>
    )
}

export default CloseIcon