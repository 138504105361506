import React from 'react';
import { Link } from 'react-router-dom';
import lightlogo from '../../assets/img/C+H-slogan-white.png';
import darklogo from '../../assets/img/C+H-slogan.png';

const Logo = ({ fill, link }) => {
    return (
        <Link 
            to={link ? link : '/'} 
            className="_logo" 
            style={{
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
            }}
        >
            {fill === "#000000" 
                ? <img src={darklogo} alt="Logo" style={{ width: '210px' }} /> 
                : <img src={lightlogo} alt="Logo" style={{ width: '210px' }} />
            }
        </Link>
    );
}

export default Logo;
