import React from 'react';
import CartIcon from './CartIcon';
import ProfileIcon from './ProfileIcon';

const CartProfileIcons = (props) => {
  return (
    <div className="_cart-and-profile-icons">
      <CartIcon fill={props.fill} />
      <ProfileIcon fill={props.fill} />
    </div>
  );
};

export default CartProfileIcons;
