import React from 'react'
import HeaderWhite from './HeaderWhite'
import HeaderContent from './HeaderContent'

class Header extends React.Component {
    render(){
        return(
            <>
                <header className={`_header ${this.props.headerTransform ? '' : '_show'}`}>
                    <HeaderContent fill="#C1B37F" hamburgerIcon={true} onClick={this.props.onClick} />
                </header>
                <HeaderWhite className={`${this.props.headerTransform ? '_show' : ''}`} onClick={this.props.onClick} backgroundColor= 'white'/>
            </>
        )
    }
}

export default Header