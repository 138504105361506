import React from 'react'
import {Link} from 'react-router-dom'
import logolight from '../../assets/img/C+H-white-ONLY.png';
import logodark from '../../assets/img/C+H-B+W-ONLY.png'

const SmallLogo = ({fill, link}) => {
    return(
        <Link to={link ? link  : '/'} className="_small-logo">
            {fill ==='#FFFFFF' ? <img src={logolight} style={{width: '210px'}}/> : <img src={logodark} style={{width: '210px'}}/>}
        </Link>
    )
}

export default SmallLogo;