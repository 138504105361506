import React, { Component } from 'react';
import SocialIcons from '../ui/SocialIcons';
import Logo from '../ui/Logo';
import SmallLogo from '../ui/SmallLogo';
import HamburgerIcon from '../ui/HamburgerIcon';
import CloseIcon from '../ui/CloseIcon';
import CartProfileIcons from '../ui/CartProfileIcons';

class HeaderContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fillColor: '#FFFFFF', // Default fill color
        };
        this.outerDivRef = React.createRef(); // Ref for the outer div
    }

    componentDidMount() {
        this.updateFillColor(); // Update color after mounting
        window.addEventListener('resize', this.updateFillColor); // Update color on window resize
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateFillColor); // Clean up the listener
    }

    getBackgroundColor = (element) => {
        const style = window.getComputedStyle(element);
        
        return style.backgroundColor;
    };

    updateFillColor = () => {
        if (this.outerDivRef.current) {
            const height = this.outerDivRef.current.offsetHeight;
            const bgColor = this.getBackgroundColor(this.outerDivRef.current);
            const baseUrl = `${window.location.origin}/`; // Base URL as origin + '/'
            
            const fillColor =
            baseUrl === window.location.href 
            ? height > 90 
            ? '#FFFFFF'
            :'#000000'
            :'#000000'

            this.setState({ fillColor });
        
        } else {
            console.warn("Outer div reference is not set");
        }

    };

    renderIcon = () => {
        const { fillColor } = this.state;
        if (this.props.hamburgerIcon) {
            return <HamburgerIcon onClick={this.props.onClick} fill={fillColor} />;
        } else {
            return <CloseIcon onClick={this.props.onClick} fill={fillColor} />;
        }
    };

    renderLogo = () => {
        const { fillColor } = this.state;
        
        if (this.props.smallLogo) {
            return <SmallLogo fill={'#000000'} />;
        } else {
            return <Logo fill={fillColor} />;
        }
    };

    render() {
        const { fillColor } = this.state;

        return (
            <div className="_inner" ref={this.outerDivRef}>
                <div className="_item">
                    <SocialIcons fill={fillColor} />
                </div>
                <div className="_item">{this.renderLogo()}</div>
                <div className="_item">
                    <div className="_left-actions-flexbox">
                        <CartProfileIcons fill={fillColor} />
                        {this.renderIcon()}
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderContent;