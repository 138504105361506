import React from 'react';

const HamburgerIcon = ({ onClick, fill }) => {
    const spanStyle = {
        backgroundColor: fill, // Use the `fill` prop to set the background color

    };

    return (
        <div className="_hamburger-icon" onClick={() => { onClick(); }}>
            <div>
                <span style={spanStyle} />
                <span style={spanStyle} />
            </div>
        </div>
    );
};

export default HamburgerIcon;
