import React, {Suspense} from 'react'
import Layout from '../layout/Layout'
import TopHeroSection from '../ui/TopHeroSection'
import TemplateOneSection from '../ui/TemplateOneSection'
import ThemeHeading from '../ui/ThemeHeading'
import SplitColumnSection from '../ui/SplitColumnSection'
import SplitColumnCarouselSection from '../ui/SplitColumnCarouselSection'
import Testimonials from '../ui/Testimonials'

import {connect} from 'react-redux'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import PartnersCarousel from '../ui/PartnersCarousel'
import {Link} from 'react-router-dom'

//hero images
// import hero1 from '../../assets/img/hero-01-homepage.jpg'
import hero2 from '../../assets/img/hero-02-homepage.jpg'
import shopBanner from '../../assets/img/shop-banner.jpg'

//images
import weavers from '../../assets/img/the-weavers.jpg'
import thelooms from '../../assets/img/the-looms.jpg'
import themakingprocess from '../../assets/img/the-making-process.jpg'
import thenaturalfibres2 from '../../assets/img/the-natural-fibres2.jpg'

// import FlocklerEmbed from '@flockler/react-flockler-embed';

class Home extends React.Component{

    render(){

        const dataHero = [
            {
                img: hero2,
                // msg: "Veld rug for Studio Duggan"
            }
        ]

        const dataSplitColumns = [
            {
                img: weavers,
                // img2: weavers2,
                content: (<>
                    <ThemeHeading>
                        <h2>THE WEAVERS</h2>
                    </ThemeHeading>
                    <p>Our weavers are the heart and soul of Coral & Hive. They are talented artisans using traditional weaving techniques - preserving an heirloom craft while bringing contemporary creations to life. They work together, spinning, weaving and singing the rugs to life on large wooden looms.</p>
                </>)
            },
            {
                img: thelooms,
                // img2: thelooms2,
                content: (<>
                    <ThemeHeading>
                        <h2>THE LOOMS</h2>
                    </ThemeHeading>
                    <p>Our rugs are handwoven on large wooden looms. We have one of the largest looms in Africa, measuring 8x5m and enabling us to custom create rugs of any size and shape. We also work with a family-owned loom in India to give our customers access to the incredible natural fibre offerings and exceptional weaving talents of India.</p>
                </>)
            },
            {
                img: thenaturalfibres2,
                // img2: thenaturalfibres,
                content: (<>
                    <ThemeHeading>
                        <h2>THE NATURAL FIBRES</h2>
                    </ThemeHeading>
                    <p>Our African fibre rugs are made using pure karakul wool and mohair, ethically sourced from sheep and goats that graze freely under the African sun. Karakul wool is beautifully textured and naturally coated in lanolin, making it organic and durable. Mohair is a silky, sumptuous fibre that is soft to the touch. We blend these fibres to create truly unique, textured living rugs.</p>
                    
                    <p>Our Indian rugs are made from sustainably sourced jute, pure New Zealand wool, cotton and silk. In India we push the creative boundaries with unique fibre blends and designs, producing some of the most inspiring rugs in our collection.</p>
                </>)
            },
            {
                img: themakingprocess,
                content: (<>
                    <ThemeHeading>
                        <h2>THE MAKING PROCESS</h2>
                    </ThemeHeading>

                    <p>The wool needed for the rug is weighed and put into a large basket for the weaver. If colour is included the fibres are dyed. The looms are warped up with strong twine and the artisan draws the design on cotton fabric, which is placed behind the warp to act as a template. Then, line-by-line, the wool is passed back and forth through the warp and the rug begins to take shape. When complete, the rug is knotted, finished, checked and wrapped up to travel to its forever home. Depending on the size, intricacy of the weave and fibre chosen, the rug can take 10-16 weeks to weave.</p>
                </>)
            }
        ]

        return(  
            <>
                <ScrollToTop />
                <Seo />
                <Layout transparent={true}>
                    <TopHeroSection data={dataHero} />
                    <ScrolledIntoView>
                        <TemplateOneSection>
                            <ThemeHeading>
                                <h1>AT CORAL & HIVE WE CREATE CUSTOM HANDWOVEN RUGS FOR THE NATURAL HOME</h1>
                            </ThemeHeading>
                            <p>To have a Coral & Hive rug underfoot is to ground yourself on a natural foundation. Each rug is a unique creation, lovingly crafted and touched by many hands. The making process involves the weaving together of many elements. From the ethical sourcing of local natural fibres, to artful design by the creative team in Cape Town and London, to our large wooden looms in South Africa and India, where singing artisans spin and weave the final creations by hand, using skills that have been passed down through generations.</p>
                        </TemplateOneSection>
                    </ScrolledIntoView>
                    
                    <SplitColumnSection data={dataSplitColumns} odd />

                    <section className='_visit-our-store-banner-section' style={{backgroundImage:`url(${shopBanner})`}}>
                        <div className='_visit-our-store-block'>
                            <div className='_hr' style={{margin: '0 auto'}} />
                            <h2>VISIT OUR STORE</h2>
                            <p>On our journey to creating the beautiful natural home, we have designed and sourced a beautiful selection of quality cushion covers to add a special touch to your space.</p>
                            <Link to="/shop" className='_button'>Shop Online</Link>
                        </div>
                    </section>

                    <SplitColumnCarouselSection slidesData={this.props.products} />
                    <section className="" style={{ backgroundColor: '#FFFFFF', marginTop: 0 }}>
                        <h1 style={{ textAlign: 'center', padding: '10px 0' }}><a href="https://www.instagram.com/coralandhive" target='_blank'>Follow us on Instagram</a></h1>
                        <div style={{ overflow: 'hidden', width: '100%', height: '600px', display: 'flex', justifyContent: 'center' }}>
                            <iframe 
                            src="https://widget.tagembed.com/2148104" 
                            style={{
                                width: '100%',
                                height: '600px',
                                border: 'none',
                                objectFit: 'cover', // Ensure the content inside scales properly
                            }} >
                            </iframe>
                        </div>
                    </section>


                    <section className="_section _section-template-1 _center">
                        <div className="_box">
                            <div className="_inner">
                                <ScrolledIntoView>
                                    <ThemeHeading>
                                        <h2>AS SEEN IN</h2>
                                    </ThemeHeading>
                                </ScrolledIntoView>
                                <ScrolledIntoView>
                                    <PartnersCarousel />
                                </ScrolledIntoView>
                            </div>                        
                        </div>
                    </section>
                    <Testimonials />
                    {/*<TemplateOneSection fluid>
                        <ScrolledIntoView>
                            <ThemeHeading>
                                <h1>INSTAFEED</h1>
                            </ThemeHeading>
                        </ScrolledIntoView>
                        <ScrolledIntoView>
                            <Suspense fallback={<div></div>}>
                                <FlocklerEmbed siteUuid="17e4403877706879dfc2b15e62e5d257" embedUuid="17eb4d05eae067fad8b3644e9eb2806c" />
                            </Suspense>
                        </ScrolledIntoView>
                    </TemplateOneSection>*/}
                </Layout>
            </>    
        )
    }
}

const mapStateToProps = state => {
    return{
        products: Object.values(state.publicProductsState)
    }
}

export default connect(mapStateToProps)(Home)