import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CartIconSVG from './CartIconSVG';

class CartIcon extends React.Component {
  amount = (qty) => {
    if (!qty) return <></>;
    if (!(qty > 0)) return <></>;
    return <span>{qty}</span>;
  };

  render() {
    const { totalQuantity } = this.props.cartState;

    return (
      <Link to="/cart" className="_cart-icon">
        <CartIconSVG fill={this.props.fill} />
        {this.amount(totalQuantity)}
      </Link>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartState: state.cartState,
  };
};

export default connect(mapStateToProps)(CartIcon);
