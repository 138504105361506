import React from 'react';

const CartIconSVG = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="23.763"
    height="29.979"
    viewBox="0 0 23.763 29.979"
  >
    <defs>
      <clipPath id="a">
        <rect width="23.763" height="29.979" fill={fill} />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path
        d="M23.761,26.861c-.059-1.705-.148-3.41-.227-5.114q-.172-3.695-.35-7.39c-.079-1.642-.141-3.285-.249-4.925A2.939,2.939,0,0,0,20.1,6.746c-.726-.016-1.453,0-2.226,0A6.1,6.1,0,0,0,15.47,1.2,5.723,5.723,0,0,0,11.493.015,5.848,5.848,0,0,0,7.278,2.162,6.161,6.161,0,0,0,5.9,6.741c-.3,0-.563-.006-.829,0a14.012,14.012,0,0,0-1.866.071A2.868,2.868,0,0,0,.827,9.462C.7,11.529.618,13.6.519,15.666Q.279,20.7.046,25.737a8.1,8.1,0,0,0,.01,1.773,2.882,2.882,0,0,0,2.322,2.4c.068.017.135.043.2.065H21.185c.177-.052.357-.1.532-.156a2.951,2.951,0,0,0,2.043-2.962M9.816,2.587a3.764,3.764,0,0,1,4.189.036,4.064,4.064,0,0,1,1.857,4.1H7.9A4.044,4.044,0,0,1,9.816,2.587M20.654,27.98q-4.4,0-8.8,0H3.173c-.824,0-1.2-.394-1.167-1.216q.34-7.266.683-14.533c.038-.811.063-1.622.114-2.433A1,1,0,0,1,3.926,8.745l11.206-.028q2.341-.006,4.682-.018a1.014,1.014,0,0,1,1.148,1.068c.1,1.985.191,3.971.285,5.956q.239,5.068.478,10.135c.015.322.025.645.037.967a1,1,0,0,1-1.108,1.154"
        transform="translate(0 0.001)"
        fill={fill}
      />
      <path
        d="M83.329,130.1a1.007,1.007,0,0,0-1.61.606,4.027,4.027,0,0,1-.994,1.911,3.836,3.836,0,0,1-3.926,1.14,3.9,3.9,0,0,1-2.883-2.908,1.8,1.8,0,0,0-.316-.66.984.984,0,0,0-1.2-.171,1.017,1.017,0,0,0-.462,1.211,6.034,6.034,0,0,0,11.743-.041.989.989,0,0,0-.349-1.088"
        transform="translate(-65.895 -119.049)"
        fill={fill}
      />
    </g>
  </svg>
);

export default CartIconSVG;
